import React, { useState } from "react";
import toast from "react-hot-toast";
import { IoMdSend } from "react-icons/io";
import { RiDeleteBin5Line } from "react-icons/ri";
import Axios from "../../../../../../config/config";
import { isValidUrl } from "../../../../../../services/helper";

const AddEditSocials = ({ whatsapp, twitter, close, refetch }) => {
  const [loading, setLoading] = useState(false);
  const [numbers, setNumbers] = useState(whatsapp);
  const [links, setLinks] = useState(twitter);
  const [numberInput, setNumberInput] = useState("");
  const [linkInput, setLinkInput] = useState("");

  const handleAddNumber = (item) => {
    if (numbers.includes(item)) {
      toast.info("Item already added");
    } else {
      const number = item.replace(/[^0-9+]/g, "");
      setNumbers([...numbers, Number(number)]);
      setNumberInput("");
    }
  };
  const handleRemoveNumber = (item) => {
    const filtered = numbers.filter((where) => where !== item);
    setNumbers(filtered);
  };
  const handleAddLink = (item) => {
    if(!isValidUrl(item)){
      toast.info("Not valid url")
      return;
    }
    if (links.includes(item)) {
      toast.info("Item already added");
    } else {
      setLinks([...links, item]);
      setLinkInput("");
    }
  };
  const handleRemoveLink = (item) => {
    const filtered = links.filter((where) => where !== item);
    setLinks(filtered);
  };

  const updateChannels = async () => {
    try {
      setLoading(true);
      const authToken = localStorage.getItem("auth_token");
      const config = {
        headers: {
          Authorization: authToken,
        },
      };
      const payload = {
        whatsapp: numbers,
        twitter: links,
      }
      const res = await Axios.post('/admin/create-update-social', payload, config);;
      setLoading(false);
      if (res.success === true) {
        toast.success("Channels Update");
        refetch()
        close();
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  return (
    <div>
      <div>
        <p className="text-lg fw-600 text-center mb-6">
          Add/Edit Support Channels
        </p>
        <p>Whatsapp Support Numbers</p>
        <div className="flex items-center px-2 border rounded-lg">
          <input
            type="text"
            value={numberInput}
            onChange={(e) => setNumberInput(e.target.value)}
            className="w-full p-2 border-none outline-none"
          />
          <IoMdSend onClick={() => handleAddNumber(numberInput)} />
        </div>
        <div className="mt-4 grid grid-cols-2 gap-4">
          {numbers.map((item) => (
            <div
              className="border p-2 rounded bg-blue-50 flex justify-between items-center"
              key={item}
            >
              <p>{item}</p>
              <RiDeleteBin5Line
                className="text-red-500 text-lg"
                onClick={() => handleRemoveNumber(item)}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="mt-6">
        <p>Twitter Support URL(s)</p>
        <div className="flex items-center px-2 border rounded-lg">
          <input
            type="text"
            value={linkInput}
            onChange={(e) => setLinkInput(e.target.value)}
            className="w-full p-2 border-none outline-none"
          />
          <IoMdSend onClick={() => handleAddLink(linkInput)} />
        </div>
        <div className="mt-4 grid grid-cols-1 gap-4">
          {links.map((item) => (
            <div
              className="border p-2 rounded bg-blue-50 flex justify-between items-center"
              key={item}
            >
              <p>{item}</p>
              <RiDeleteBin5Line
                className="text-red-500 text-lg shrink-0"
                onClick={() => handleRemoveLink(item)}
              />
            </div>
          ))}
        </div>
      </div>
      <button
        onClick={updateChannels}
        className="mt-8 bg-primary text-white w-full py-2 fw-600 rounded-lg"
      >
        {loading ? "Updating" : "Update Channels"}
      </button>
    </div>
  );
};

export default AddEditSocials;
