import React from "react";
import { FaSquareWhatsapp, FaSquareXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";
import useFetchHook from "../../../../../hooks/useFetchHook";

const ContactDetails = () => {
  const { data, loading } = useFetchHook("/user/get/supportsocials");
  const whatsapp = data?.whatsapp ? JSON.parse(data.whatsapp) : [];
  const twitter = data?.twitter ? JSON.parse(data.twitter) : [];
  return (
    <div>
        <p className="fw-600 text-xl text-center mb-4">Support contact links</p>
      {!loading && (
        <div className="mt-6">
          <div className="mt-4 lg:mt-8 grid lg:grid-cols-2 gap-5">
            {whatsapp?.map((item) => (
              <Link to={`https://wa.me/${item}`} className="block cursor-pointer" key={item}>
                <div className="flex gap-x-3 cursor-pointer">
                <FaSquareWhatsapp className="text-2xl lg:text-3xl shrink-0 text-[#25D366]" />
                <p className="fw-600 text-lg">+{item}</p>
                </div>
                <p className="mt-1 underline text-primary fs-500 lg:pl-8">Click to start chat</p>
              </Link>
            ))}
          </div>
          <div className="mt-4 lg:mt-8 grid lg:grid-cols-2 gap-5">
            {twitter?.map((item, i) => (
              <div className="flex gap-x-3" key={i}>
                <FaSquareXTwitter className="text-2xl lg:text-3xl" />
                <Link
                  to={item}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="fw-600 text-lg"
                >
                  {item}
                </Link>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactDetails;
