import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Header from "./header";
import Dashboard from "./pages/Dasboard";
import Meetings from "./pages/Meetings";
import DeliveryAddresses from "./pages/DeliveryAddresses";
import Notify from "./pages/Notify";
import Orders from "./pages/Orders";
import Products from "./pages/Products";
import Sidebar from "./sidebar";
import Settings from "./pages/Settings"
import Transactions from "./pages/Transactions";
import Projects from "./pages/Project";
import Cart from "./pages/Cart";
import { AllProject } from "./pages/allprojects";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarsStaggered } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { SwitchAccount } from "./pages/SwitchAccount";
import Ppartners from "./pages/Ppartners";
import Spartners from "./pages/Spartner";
import Clients from "./pages/Clients";
import ProductsAdmin from "./pages/ProductsAdmin";
import ProductDetailsAdmin from "./pages/ProductDetails";
import ProductsCategory from "./pages/ProductCat";
import OrdersAdmin from "./pages/OrdersAdmin";
import KycForm from "./pages/KycForm";
import OrderDetails from "./pages/OrderDetails";
import UserDetails from "./pages/UserDetail";
import Review from "./pages/Reviews";
import ProjectsAdmin from "./pages/ProjectsAdmin";
import ProjectDetails from "./pages/ProjectDetailsAdmin";
import ProjectRequest from "./pages/ProjectRequest";
import Blog from "./pages/Blog";
import BlogPost from "./pages/Blog/CreatePost";
import EditPost from "./pages/Blog/EditPost";
import ProjectDetailsClient from "./pages/ProjectDetailsClient";
import OrderRequest from "./pages/OrderRequest";
import UserOrderDetail from "./pages/UserOrderDetail";
import KycAlert, { KycModals } from "../assets/KycAlert";
import { Subscription } from "./pages/Subscribtion";
import ProjectFile from "./pages/projects/ProjectFile";
import ProjectCategory from "./pages/ProjectCat";
import Sadmins from "./pages/SAdmins";
import { ViewBlog } from "./pages/Blog/ViewBlog";
import { Testimonial } from "./pages/Testimonial";
import SubAdminDetails from "./pages/SubAdminDetails";
import AdminTransactions from "./pages/AdminTransactions";
import Notification from "./pages/Notification";
import { SmartCalc } from "./pages/SmartCalc";
import AdminMeeting from "./pages/AdminMeeting";
import AdminInbox from "./pages/Admins/InboxAdmin";
import UserInbox from "./pages/Users/InboxUser";
import { TransactionDetails } from "./pages/Transaction/TransactionItem";
import ServiceCategory from "./pages/ServiceCat";
import ServiceCategoryForm from "./pages/ServiceCatForm";
import { ServiceRequest } from "./pages/ServiceRequest";
import AdminSub from "./pages/AdminSub";
import ServicePartnerMeeting from "./pages/ServicePartnerMeeting";
import ProjectDetailsPartner from "./pages/ProjectDetailsPartner";
import RefundOrders from "./pages/RefundOrder";
import BlogCategory from "./pages/BlogCat";
import { PartnerPayment } from "./pages/PartnerPayment";
import ProjectSettings from "./pages/ProjectSettings";
import GtiProjects from "./pages/projects/GtiProjects";
import GtiProjectDetails from "./pages/projects/GtiProjectDetails";
import SupportLines from "./pages/Admins/support-lines";
import AdminAnnouncement from "./pages/Admins/AdminAnnouncement";
import AnnounceUser from "./pages/Users/AnnounceUser";

export default function ClientDashboard() {

    const navigate = useNavigate()
    const [sidebarReduce, setSidebarReduce] = useState(false)


    return (
      <div>
        <div className="font-primary">
          <Header />
          <div
            className=""
            style={{ width: sidebarReduce ? "100px" : "200px" }}
          >
            <Sidebar />
          </div>
          <div className="fixed top-0 hidden lg:block sub-menu z-50">
            <FontAwesomeIcon
              icon={faBarsStaggered}
              size="2x"
              className="text-2xl lg:ml-4 cursor-pointer"
              onClick={() => setSidebarReduce(!sidebarReduce)}
            />
          </div>
          <KycModals />
          <div
            style={{
              width: sidebarReduce ? "calc(100% - 49px)" : "",
              zIndex: sidebarReduce ? "49" : "",
            }}
            className="lg:main-p pt-21 home-bg pb-10 relative"
          >
            <KycAlert />
            <Routes>
              <Route path="" element={<Dashboard />} />
              <Route path="orders" element={<Orders />} />
              <Route path="order-request" element={<OrderRequest />} />
              <Route path="products" element={<Products />} />
              <Route path="projects" element={<Projects />} />
              <Route path="cart" element={<Cart />} />
              <Route path="client" element={<Clients />} />
              <Route path="blog" element={<Blog />} />
              <Route path="blog-category" element={<BlogCategory />} />
              <Route path="createpost" element={<BlogPost />} />
              <Route path="viewpost/:postId" element={<ViewBlog />} />
              <Route path="editpost/:postId" element={<EditPost />} />
              <Route path="userdetails" element={<UserDetails />} />
              <Route path="productscategory" element={<ProductsCategory />} />
              <Route path="productsadmin" element={<ProductsAdmin />} />
              <Route
                path="productdetailadmin"
                element={<ProductDetailsAdmin />}
              />
              <Route path="ordersadmin" element={<OrdersAdmin />} />
              <Route path="order-refund" element={<RefundOrders />} />
              {/* <Route path="myorderdetails" element={<OrderDetailsClient />} /> */}
              <Route path="orderadmindetail" element={<OrderDetails />} />
              <Route
                path="order-detail/:orderId"
                element={<UserOrderDetail />}
              />
              <Route path="projectsadmin" element={<ProjectsAdmin />} />
              <Route path="projectadmindetails" element={<ProjectDetails />} />
              <Route
                path="projectpartnerdetails"
                element={<ProjectDetailsPartner />}
              />
              <Route
                path="myprojectdetails"
                element={<ProjectDetailsClient />}
              />
              <Route path="projectrequest" element={<ProjectRequest />} />
              <Route path="project-settings" element={<ProjectSettings />} />
              <Route path="gtiprojects" element={<GtiProjects />} />
              <Route path="projectgtidetails" element={<GtiProjectDetails />} />
              <Route
                path="service-request/:projectId"
                element={<ServiceRequest />}
              />
              <Route path="service-category" element={<ServiceCategory />} />
              <Route path="productpartner" element={<Ppartners />} />
              <Route path="servicepartner" element={<Spartners />} />
              <Route path="sub-admin" element={<Sadmins />} />
              <Route path="admin-details" element={<SubAdminDetails />} />
              <Route path="allprojects" element={<AllProject />} />
              <Route path="projectfile" element={<ProjectFile />} />
              <Route path="service-provider" element={<ProjectCategory />} />
              <Route
                path="service-provider/buildForm/:id/:name"
                element={<ServiceCategoryForm />}
              />
              <Route path="meetings" element={<Meetings />} />
              <Route path="delivery" element={<DeliveryAddresses />} />
              <Route
                path="service-meetings"
                element={<ServicePartnerMeeting />}
              />
              <Route path="admin-meetings" element={<AdminMeeting />} />
              <Route path="transact" element={<Transactions />} />
              <Route path="transact-details" element={<TransactionDetails />} />
              <Route path="transactions" element={<AdminTransactions />} />
              <Route path="payout" element={<PartnerPayment />} />
              <Route path="delivery" element={<address />} />
              <Route path="notification" element={<Notify />} />
              <Route path="notify" element={<Notification />} />
              <Route path="switch" element={<SwitchAccount />} />
              <Route path="review" element={<Review />} />
              <Route path="inbox" element={<UserInbox />} />
              <Route path="announce" element={<AnnounceUser />} />
              <Route path="messages" element={<AdminInbox />} />
              <Route path="announcement" element={<AdminAnnouncement />} />
              <Route path="kyc" element={<KycForm />} />
              <Route path="testimonial" element={<Testimonial />} />
              <Route path="subscription" element={<Subscription />} />
              <Route path="settings" element={<Settings />} />
              <Route path="smart-calc" element={<SmartCalc />} />
              <Route path="admin-sub" element={<AdminSub />} />
              <Route path="support-lines" element={<SupportLines />} />
              <Route path="" element={<Dashboard />} />
              <Route path="" element={<Dashboard />} />
            </Routes>
          </div>
          <div
            style={{
              width: sidebarReduce ? "calc(100% - 49px)" : "",
              zIndex: sidebarReduce ? "49" : "",
            }}
            className="lg:flex relative bg-white text-center lg:main-p px-5 py-5 text-primary fw-500 justify-between fs-400"
          >
            <div className="flex mb-5 lg:mb-0">
              <ul className="flex w-full justify-evenly lg:justify-start">
                <li onClick={() => navigate("/")} className="cursor-pointer">
                  Homepage
                </li>
                <li
                  className="lg:px-6 px-3 cursor-pointer"
                  onClick={() => navigate("/shop")}
                >
                  Products
                </li>
                <li
                  onClick={() => navigate("/services")}
                  className="cursor-pointer"
                >
                  Services
                </li>
              </ul>
            </div>
            <div>
              Copyright &copy; {new Date().getFullYear()} Build On The Go
            </div>
          </div>
        </div>
      </div>
    );
}