import { Breadcrumbs } from "@material-tailwind/react";
import React, { useState } from "react";
import { MdAdd } from "react-icons/md";
import { Link } from "react-router-dom";
import useFetchHook from "../../../../../../hooks/useFetchHook";
import { FaSquareWhatsapp } from "react-icons/fa6";
import { FaSquareXTwitter } from "react-icons/fa6";
import AddEditSocials from "./add-edit-social";

const SupportLines = () => {
  const { data, loading, refetch } = useFetchHook("/admin/get/supportsocials");
  const whatsapp = data?.whatsapp ? JSON.parse(data.whatsapp) : [];
  const twitter = data?.twitter ? JSON.parse(data.twitter) : [];

  const [showModal, setShowModal] = useState(false);

  return (
    <div>
      <div className="min-h-screen fs-500 relative">
        <div className="w-full py-8 bg-white px-4 lg:flex justify-between items-center">
          <div>
            <p className="text-2xl fw-600">Support Channels</p>
            <p className="fs-400 text-gray-600 mt-2">
              Set all contact handles and informations for users to reach out.
            </p>
            <Breadcrumbs className="bg-white pl-0 mt-4">
              <Link to="/" className="opacity-60">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                </svg>
              </Link>
              <Link to="/dashboard" className="opacity-60">
                <span>Dashboard</span>
              </Link>
              <Link to="" className="">
                <span>Support channels</span>
              </Link>
            </Breadcrumbs>
          </div>
        </div>
        <div className="p-4">
          <div className="w-full py-8 bg-white px-4">
            <div className="flex items-center justify-between">
              <div className="flex gap-x-2 items-center">
                <p className="w-3 h-3 circle bg-primary"></p>
                <p className="fw-600 text-xl">Channels</p>
              </div>
              <button
                onClick={() => setShowModal(true)}
                className="flex gap-x-2 text-lg items-center text-primary fw-600 cursor-pointer"
              >
                <MdAdd /> Add/Edit
              </button>
            </div>
            {!loading && (
              <div className="mt-6">
                <div className="mt-4 lg:mt-8 grid lg:grid-cols-4">
                  {whatsapp?.map((item) => (
                    <div className="flex gap-x-3" key={item}>
                      <FaSquareWhatsapp className="text-2xl lg:text-3xl text-[#25D366]" />
                      <p className="fw-600 text-lg">+{item}</p>
                    </div>
                  ))}
                </div>
                <div className="mt-4 lg:mt-8 grid lg:grid-cols-4">
                  {twitter?.map((item, i) => (
                    <div className="flex gap-x-3" key={i}>
                      <FaSquareXTwitter className="text-2xl lg:text-3xl" />
                      <Link to={item} target="_blank" rel="noopener noreferrer" className="fw-600 text-lg">
                        {item}
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {showModal && (
        <div
          className="fixed font-primary top-0 left-0 w-full h-screen bg-op center-item z-40"
          onClick={() => setShowModal(false)}
        >
          <div
            className="bg-white px-4 lg:w-5/12 rounded-md max-h-[70vh] overflow-y-auto overscroll-none  w-11/12 pt-8 pb-8 lg:px-10 shadow fw-500 scale-ani"
            onClick={(e) => e.stopPropagation()}
          >
            <AddEditSocials
              close={() => setShowModal(false)}
              whatsapp={whatsapp}
              twitter={twitter}
              refetch={refetch}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SupportLines;
