import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FetchAdminNotification from "../../hooks/useFetchAdminNotification";
import FetchUserNotification from "../../hooks/useFetchUserNotification";
import { getMe } from "../../redux/actions/authAction";

const ProtectedRoute = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const loading = useSelector((state) => state.auth.isLoading);
  return isAuthenticated && !loading ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace />
  );
};

export default ProtectedRoute;

export const Protected = ({ children }) => {
  const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getMe());   
    }, [])
  return (
    <div>
      {/* <FetchMeIfAuthenticated /> */}
      <FetchAdminNotification />
      <FetchUserNotification />
      {children}
    </div>
  );
};
